import React, { FC, Fragment, memo, useState, useRef, useContext } from 'react'
import { ScreenContext } from 'react-components'

import HeroSection from './hero-section'
import HeaderSection from './header-section'
import HowItWorksSection from './how-it-works-section'
import TestimonialsSection from './testimonials-section'
import FAQSection from './faq-section'
import CustomerSupportSection from './customer-support-section'
import FooterSection from './footer-section'
import PremiumPartnershipSection from './premium-partnership-section'
import BenefitsSection from './benefits-section'
import MultilingualWebsiteSection from './mlw-section'
import StickyButtons from './sticky-button'
import {
    BenefitsSectionTransport,
    CustomerSupportSectionTransport,
    FAQSectionTransport,
    FooterBannerSectionTransport,
    HeroSectionTransport,
    HowItWorksSectionTransport,
    MoneyBackGuaranteeTransport,
    FairnessPrincipleTransport,
    NavigationTransport,
    TestimonialsSectionTransport,
    TrustbarSectionTransport,
} from '@common/transports/home-page-transport'
import HeaderSectionTransport from '@common/transports/layout/header-section-transport'
import FooterSectionTransport from '@common/transports/layout/footer-section-transport'
import { useIntersectionObserverEffect } from '@src/hooks/dom'
import { connect } from 'react-redux'
import {
    getNotifications,
    disposeNotification,
    clearNotification,
    NotificationState,
    NotificationMessageState,
} from '@src/redux/notification-channel'
import RootState, { MapDispatchToProps } from '../../redux/types'
import NotificationSnackBar from '../common/notification-snackbar'
import isEmpty from 'lodash/isEmpty'
import { AnalyticsService } from '@src/services'
import { homePageEventCategories } from '@src/services/analytics-service/categories'
import { homePageEventLabels } from '@src/services/analytics-service/labels'
import { basicEventActions } from '@src/services/analytics-service/actions'

interface OwnProps {
    navigation: NavigationTransport
    headerSection: HeaderSectionTransport
    heroSection: HeroSectionTransport
    trustbarSection: TrustbarSectionTransport
    benefitsSection: BenefitsSectionTransport
    howItWorksSection: HowItWorksSectionTransport
    testimonialsSection: TestimonialsSectionTransport
    faqSection: FAQSectionTransport
    footerBannerSection: FooterBannerSectionTransport
    footerSection: FooterSectionTransport
    customerSupportSection: CustomerSupportSectionTransport
    moneyBackGuaranteeSection: MoneyBackGuaranteeTransport
    fairnessPrincipleSection: FairnessPrincipleTransport
    isLoggedIn: boolean
}

interface StateProps {
    notifications: NotificationState[]
}

interface DispatchProps {
    disposeNotification: (message: NotificationMessageState) => void
    clearNotification: (id: string) => void
}

type Props = DispatchProps & StateProps & OwnProps

const _intersectionObserverOptions = { root: null, threshold: 0.1 }

const HomePage: FC<Props> = ({
    navigation,
    headerSection,
    heroSection,
    howItWorksSection,
    testimonialsSection,
    faqSection,
    footerSection,
    customerSupportSection,
    moneyBackGuaranteeSection,
    fairnessPrincipleSection,
    isLoggedIn,
    disposeNotification,
    notifications,
    clearNotification,
}) => {
    const [isActionButtonVisible, setIsActionButtonVisible] = useState(false)
    const isRegistrationFormVisible = !isEmpty(heroSection?.registrationForm)

    const [_heroSectionRef] = useIntersectionObserverEffect(entries => {
        const [actionButton] = entries
        if (actionButton && !isRegistrationFormVisible) {
            if (actionButton.intersectionRatio < 0.1) {
                setIsActionButtonVisible(true)
            } else {
                setIsActionButtonVisible(false)
            }
        }
    }, _intersectionObserverOptions)

    const targetRef = useRef<HTMLDivElement>(null)
    const [isAdvertisementGlowing, setAdvertisementGlowing] = useState<boolean>(false)
    const { isMobile } = useContext(ScreenContext)

    const handleAdvertisementGlow = () => {
        AnalyticsService.trackEvent(
            basicEventActions.CLICK,
            homePageEventCategories.VERMIETEN_HAHALLOWEEN_OFFER,
            homePageEventLabels.offerSection.VERMIETEN_HAHALLOWEEN_OFFER,
        )
        setAdvertisementGlowing(true) // Set glowing state to true
        setTimeout(() => setAdvertisementGlowing(false), 2000) // Remove glow after 2 seconds
    }

    return (
        <Fragment>
            <NotificationSnackBar messages={notifications} onDismiss={clearNotification} />
            <HeaderSection
                headerSection={headerSection}
                navigation={navigation}
                isLoggedIn={isLoggedIn}
                isActionButtonVisible={isActionButtonVisible}
                isRegistrationFormVisible={isRegistrationFormVisible}
                handleAdvertisementGlow={handleAdvertisementGlow}
            />
            <main>
                <HeroSection
                    heroSection={heroSection}
                    customerSupport={customerSupportSection}
                    isLoggedIn={isLoggedIn}
                    sectionRef={_heroSectionRef}
                    disposeNotification={disposeNotification}
                    isRegistrationFormVisible={isRegistrationFormVisible}
                    registrationFormRef={targetRef}
                    isAdvertisementGlowing={isAdvertisementGlowing}
                />
                <TestimonialsSection testimonialsSection={testimonialsSection} />
                <BenefitsSection
                    fairnessPrincipleModal={fairnessPrincipleSection.modal}
                    moneyBackGuaranteeModal={moneyBackGuaranteeSection.modal}
                />
                <PremiumPartnershipSection />
                <MultilingualWebsiteSection />
                <HowItWorksSection
                    howItWorksSection={howItWorksSection}
                    isLoggedIn={isLoggedIn}
                    isRegistrationFormVisible={isRegistrationFormVisible}
                />
                <FAQSection faqSection={faqSection} />
                <CustomerSupportSection customerSupportSection={customerSupportSection} />
            </main>
            <FooterSection footerSection={footerSection} />
            {isMobile && <StickyButtons targetRef={targetRef} />}
        </Fragment>
    )
}

const mapStateToProps: (state: RootState) => StateProps = state => {
    return {
        notifications: getNotifications(state),
    }
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, object, RootState> = dispatch => ({
    disposeNotification: message => dispatch(disposeNotification(message)),
    clearNotification: id => dispatch(clearNotification(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(HomePage))
